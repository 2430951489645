import React, { useEffect, useState } from 'react'
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { postApi } from '../../services/api';

const Overview = () => {
    const hospitalDataString = localStorage.getItem('hospital') as string;
    const hospitalData: any = JSON.parse(hospitalDataString);

  return (
    <div>
        <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Hospital Name:</label>
                            <span className="fs-5 text-gray-800">{hospitalData?.name}</span>
                        </div>
                        {/* <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Hospital Slug:</label>
                            <a href="https://hms-saas.infyom.com/h/02447960" className="show-btn text-decoration-none" target="_blank">02447960
                                <span className="ms-2 text-gray-800 text-primary"><FaArrowUpRightFromSquare/></span>
                            </a>
                        </div> */}
                        <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Email:</label>
                            <span className="fs-5 text-gray-800">{hospitalData.emailAddress}</span>
                        </div>
                        <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Time Schedule:</label>
                            <span className="fs-5 text-gray-800">{hospitalData.hours}</span>
                        </div>
                        <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Phone:</label>
                            <span className="fs-5 text-gray-800">{hospitalData.mobileNumber}</span>
                        </div>
                        <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Address:</label>
                            <span className="fs-5 text-gray-800">{hospitalData.address}</span>
                        </div>
                        <div className="col-sm-6 d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Location:</label>
                            <span className="fs-5 text-gray-800">{hospitalData.location}</span>
                        </div>
                        <div className="d-flex flex-column mb-md-10 mb-5">
                            <label htmlFor="name" className="pb-2 fs-5 text-gray-600">Description:</label>
                            <span className="fs-5 text-gray-800">{hospitalData.description}</span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Overview