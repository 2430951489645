import React, { useState } from "react";
import FileUploader from "../common/FileUploader";
import { FaQuestionCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { postApi } from "../services/api";
import { toast } from "react-toastify";

const EditHospital = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state;

  const [locations, setLocations] = useState(item.location);
  const [name, setName] = useState(item.name);
  const [address, setAddress] = useState(item.address);
  const [emailAddress, setEmailAddress] = useState(item.emailAddress);
  const [mobileNumber, setMobileNumber] = useState(item.mobileNumber);
  const [hours, setHours] = useState(item.hours);
  const [description, setDescription] = useState(item.description);
  const [mrp, setMrp] = useState(item.mrp);
  const [discount, setDiscount] = useState(item.discount);
  const [total, setTotal] = useState(item.total);
  const [errors, setErrors] = useState<any>({});

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const editHospitalData = async () => {
    const validationErrors: any = {};
    if (!emailAddress) {
      validationErrors.email = "Email is required.";
    } else if (!validateEmail(emailAddress)) {
      validationErrors.email = "Please enter a valid email address.";
    }

    if (!name) {
      validationErrors.name = "Hospital Name is required.";
    }

    if (!mobileNumber) {
      validationErrors.mobileNumber = "Mobile Number is required.";
    }

    if (!address) {
      validationErrors.address = "Address is required.";
    }

    if (!location) {
      validationErrors.location = "Location is required.";
    }

    if (!hours) {
      validationErrors.hours = "Hours is required.";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const finalData = {
        hospitalId: item._id,
        name: name,
        emailAddress: emailAddress,
        mobileNumber: mobileNumber,
        address: address,
        location: locations,
        hours: hours,
        description: description,
        mrp: mrp,
        discount: discount,
        total: total,
        image:
          "https://thumbs.dreamstime.com/z/happy-female-physician-therapist-general-practitioner-looking-camera-standing-hospital-profile-staff-proud-professional-207087745.jpg",
      };

      const response = await postApi("admin/hospital/update", finalData);

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/hospitals");
      } else {
        const error = response.response.data.error;
        for (const er in error) {
          toast.error(error[er]);
        }
      }
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="content d-flex flex-column flex-column-fluid pt-7">
            <div className="container-fluid">
              <div className="d-md-flex align-items-center justify-content-between mb-5">
                <h1 className="mb-0 title_sm">Edit Hospital</h1>
                <div className="text-end mt-4 mt-md-0">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate("/hospitals")}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form action="#">
                  <div className="row mb-5">
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="hospitalName" className="form-label">
                          Hospital Name <span className="required"></span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="hospitalName"
                          name="hospitalName"
                          placeholder="Enter Hospital Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && (
                          <span className="error">{errors.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="email" className="form-label">
                          Email <span className="required"></span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        {errors.email && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="phone" className="form-label">
                          Phone <span className="required"></span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Enter Phone"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                        {errors.mobileNumber && (
                          <span className="error">{errors.mobileNumber}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="location" className="form-label">
                          Location <span className="required"></span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="location"
                          name="location"
                          placeholder="Enter Location"
                          value={locations}
                          onChange={(e) => setLocations(e.target.value)}
                        />
                        {errors.location && (
                          <span className="error">{errors.location}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          placeholder="Enter Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="address" className="form-label">
                          Address <span className="required"></span>
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          placeholder="Enter Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        {errors.address && (
                          <span className="error">{errors.address}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="city" className="form-label">
                          Hours <span className="required"></span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          placeholder="Enter City"
                          value={hours}
                          onChange={(e) => setHours(e.target.value)}
                        />
                        {errors.hours && (
                          <span className="error">{errors.hours}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="mrp" className="form-label">
                          MRP
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mrp"
                          name="mrp"
                          placeholder="Enter MRP"
                          value={mrp}
                          onChange={(e) => setMrp(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="discount" className="form-label">
                          Discount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="discount"
                          name="discount"
                          placeholder="Enter Discount"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="total" className="form-label">
                          Total
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="total"
                          name="total"
                          placeholder="Enter Total"
                          value={total}
                          onChange={(e) => setTotal(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="mb-sm-7 mb-4">
                        <label htmlFor="total" className="form-label">
                          Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          name="image"
                          placeholder="Image"
                          // value={item.image}
                        />
                      </div>
                    </div> */}
                    {/* <FileUploader
                      label=" Image"
                      requiredMarker={<span className="required"></span>}
                      icon={<FaQuestionCircle />}
                    /> */}
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary me-2"
                        onClick={() => editHospitalData()}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => navigate("/hospitals")}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHospital;
