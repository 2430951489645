import React, { ReactNode } from "react";
// import Sidebar from "../Common/Sidebar";
// import DashboardBody from "../DashboardBody";
// import Header from "../Common/Header";
// import { navConfig } from "../../routes/navConfig";
import { useLocation } from "react-router";
import { title } from "process";
import { navConfig } from "../../routes/navConfig";
import { Sidebar } from "../common/Sidebar";
import Header from "../common/Header";

interface LayoutProps {
  children: ReactNode;
}

const getSubTab = (pathname: string): any[] => {
  switch (pathname) {
    case "/appointment":
      return [
        { title: "Appointments", path: "/appointment" },
        {
          title: "Appointment Transaction",
          path: "/?tab=appointment-transaction",
        },
      ];

    case "/dashboard":
      return [{ title: "Dashboard", path: "/dashboard" }];
    case "/hospitals":
      return [{ title: "Hospital", path: "/hospitals" }];

    default:
      return [];
  }
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location: any = useLocation();

  return (
    <div>
      <Sidebar routes={navConfig["super_admin"]} />
      <div className="dashboard-body">
        <Header
          linkTexts={getSubTab(location?.pathname.toString())}
          children=""
        />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
