import React from "react";
import {
  BsList,
  BsCalendarCheck,
  BsFillHospitalFill,
  BsFillPersonFill,
  BsCalendarFill,
  BsFillFileEarmarkMedicalFill,
  BsPersonBoundingBox,
  BsGearFill,
  BsFileMedical,
  BsPieChartFill,
  BsPersonCheckFill,
} from "react-icons/bs";
import { Login } from "../components/auth-pages/Login";
import SuperAdminDashboard from "../components/Dashboard/SuperAdminDashboard";
import HospitalListing from "../components/Hospital/HospitalListing";
import HospitalListingTabs from "../components/Hospital/HospitalListingTabs";
import AddHospital from "../components/Hospital/AddHospital";
import EditHospital from "../components/Hospital/EditHospital";
// import Admin_listing from "../components/Admin/AdminListing";
import SubscriptionPlans from "../components/Billings/SubscriptionPlans";
import EnquiriesListing from "../components/Enquiries/EnquiriesListing";
import HospitalTypeListing from "../components/HospitalType/HospitalTypeListing";

export interface RouteType {
  title: string;
  path: string;
  subPath?: string;
  isShowInSidebar?: boolean;
  component: JSX.Element;
  icon: any;
}

export const commonRoutes: RouteType[] = [
  {
    title: "Login",
    path: "/login",
    component: <Login />,
    icon: "",
  },
];

export const superAdminRoutes: RouteType[] = [
  {
    title: "Dashboard",
    path: "/dashboard",
    subPath: "dashboard",
    isShowInSidebar: true,
    component: <SuperAdminDashboard />,
    icon: <BsCalendarCheck />,
  },
  // {
  //   title: "Admins",
  //   path: "/admins",
  //   subPath: "admin",
  //   isShowInSidebar: true,
  //   component: <Admin_listing />,
  //   icon: <BsFillPersonFill />,
  // },
  // Add other items similarly
  {
    title: "Hospital Type",
    path: "/hospital-type",
    subPath: "hospital-type",
    isShowInSidebar: false,
    component: <HospitalTypeListing />,
    icon: <BsFillHospitalFill />,
  },
  {
    title: "Hospitals",
    path: "/hospitals",
    subPath: "hospitals",
    isShowInSidebar: true,
    component: <HospitalListing />,
    icon: <BsCalendarFill />,
  },
  {
    title: "Hospitals",
    path: "/hospitals/:id",
    subPath: "hospitals",
    isShowInSidebar: false,
    component: <HospitalListingTabs />,
    icon: <BsCalendarFill />,
  },
  {
    title: "Hospitals",
    path: "/hospitals/create",
    subPath: "hospitals",
    isShowInSidebar: false,
    component: <AddHospital />,
    icon: <BsCalendarFill />,
  },
  {
    title: "Hospitals",
    path: "/hospitals/update/:id",
    subPath: "hospitals",
    isShowInSidebar: false,
    component: <EditHospital />,
    icon: <BsCalendarFill />,
  },
  // {
  //   title: "Billing",
  //   path: "/subscription-plans",
  //   subPath: "subscription",
  //   isShowInSidebar: true,
  //   component: <SubscriptionPlans />,
  //   icon: <BsFillFileEarmarkMedicalFill />,
  // },
  // {
  //   title: "Subscribers",
  //   path: "/subscribers",
  //   subPath: "subscriber",
  //   isShowInSidebar: true,
  //   component: <Subscribers_listing />,
  //   icon: <BsPersonBoundingBox />,
  // },
  // {
  //   title: "Enquiries",
  //   path: "/enquiries",
  //   subPath: "enquiry",
  //   isShowInSidebar: true,
  //   component: <EnquiriesListing />,
  //   icon: <BsGearFill />,
  // },

  // ---
  // {
  //   title: "Landing CMS",
  //   path: "/cms",
  //   subPath: "section",
  //   isShowInSidebar: true,
  //   component: <Dashboard />,
  //   icon: <BsFileMedical />,
  // },
  // ---
  // {
  //   title: "Setting",
  //   path: "/settings",
  //   subPath: "setting",
  //   isShowInSidebar: true,
  //   component: <Setting />,
  //   icon: <BsPieChartFill />,
  // },
  // {
  //     title: "Patients",
  //     path: '/patients',
  //     subPath: 'patient',
  //     isShowInSidebar:true,
  //     component: <Dashboard/>,
  //     icon: <BsPersonCheckFill/>
  // }
];

export interface NavConfigType {
  [key: string]: RouteType[];
}

export const navConfig: NavConfigType = {
  common: commonRoutes,
  super_admin: superAdminRoutes,
};
