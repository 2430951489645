import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/index.scss';
import Routes from "./routes"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes />
    </>
    // <div className="App">
    //     <Login/>
    // </div>
  );
}

export default App;
