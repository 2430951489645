import React, { useEffect, useState } from "react";
import { postApi } from "../services/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddHospital = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [images, setImages] = useState("");
  const [logos, setLogos] = useState("");
  const [errors, setErrors] = useState<any>({});

  const getFileInfo = (e: any) => {
    const formData: any = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);
    formData.append("fileType", "images");
    setImage(formData);
  };

  const getLogoInfo = (e: any) => {
    const formData: any = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);
    formData.append("fileType", "logos");
    setLogo(formData);
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const addHospital = async () => {
    const validationErrors: any = {};
    if (!emailAddress) {
      validationErrors.email = "Email is required.";
    } else if (!validateEmail(emailAddress)) {
      validationErrors.email = "Please enter a valid email address.";
    }

    if (!name) {
      validationErrors.name = "Hospital Name is required.";
    }

    if (!cPassword) {
      validationErrors.cPassword = "Confirm Password is required.";
    }

    if (!password) {
      validationErrors.password = "Password is required.";
    }

    if (!mobileNumber) {
      validationErrors.mobileNumber = "Mobile Number is required.";
    }

    if (!address) {
      validationErrors.address = "Address is required.";
    }

    if (!location) {
      validationErrors.location = "Location is required.";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const finalData = {
        name: name,
        emailAddress: emailAddress,
        password: password === cPassword ? password : "",
        mobileNumber: mobileNumber,
        address: address,
        location: location,
        description: description,
        image: images,
        logo: logos,
      };

      const response = await postApi("admin/hospital/create", finalData);

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/hospitals");
      } else {
        const error = response.response.data.error;
        for (const er in error) {
          toast.error(error[er]);
        }
      }
    }
  };

  const uploadImages = async (image: any) => {
    const response = await postApi("admin/profile/upload", image);
    if (response.status === 200) {
      toast.success(response.data.message);
      setImages(response.data.data.filename.url);
    } else {
      toast.error(response.data.message);
    }
  };

  const uploadLogo = async (image: any) => {
    const response = await postApi("admin/profile/upload", image);
    if (response.status === 200) {
      toast.success(response.data.message);
      setLogos(response.data.data.filename.url);
    } else {
      toast.error(response.data.message);
    }
  };

  useEffect(() => {
    if (image) {
      uploadImages(image);
    }
  }, [image]);

  useEffect(() => {
    if (logo) {
      uploadLogo(logo);
    }
  }, [logo]);

  return (
    <div>
      <div className="content d-flex flex-column flex-column-fluid pt-7">
        <div className="container-fluid">
          <div className="d-md-flex align-items-center justify-content-between mb-5">
            <h1 className="mb-0 title_sm">New Hospital</h1>
            <div className="text-end mt-4 mt-md-0">
              <button
                className="btn btn_style"
                onClick={() => navigate("/hospitals")}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="row mb-5">
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="hospitalName" className="form-label">
                      Hospital Name <span className="required"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="hospitalName"
                      name="hospitalName"
                      placeholder="Enter Hospital Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="email" className="form-label">
                      Email <span className="required"></span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="password" className="form-label">
                      Password <span className="required"></span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <span className="error">{errors.password}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password <span className="required"></span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={(e) => setCPassword(e.target.value)}
                    />
                    {errors.cPassword && (
                      <span className="error">{errors.cPassword}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="phone" className="form-label">
                      Phone <span className="required"></span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Enter Phone"
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                    {errors.mobileNumber && (
                      <span className="error">{errors.mobileNumber}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="location" className="form-label">
                      Location <span className="required"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      placeholder="Enter Location"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    {errors.location && (
                      <span className="error">{errors.location}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      placeholder="Enter Description"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="address" className="form-label">
                      Address <span className="required"></span>
                    </label>
                    <textarea
                      className="form-control"
                      id="address"
                      name="address"
                      placeholder="Enter Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {errors.address && (
                      <span className="error">{errors.address}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="total" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="image"
                      name="image"
                      placeholder="Image"
                      onChange={(e) => getFileInfo(e)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-sm-7 mb-4">
                    <label htmlFor="total" className="form-label">
                      Logo
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="Logo"
                      name="Logo"
                      placeholder="Logo"
                      onChange={(e) => getLogoInfo(e)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn_style me-2"
                    onClick={() => addHospital()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate("/hospitals")}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHospital;
