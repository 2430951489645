import { useEffect, useState } from "react";
import {
  FaMoon,
  FaBell,
  // FaUser,
  // FaLock,
  // FaGlobe,
  FaSignOutAlt,
  FaCalendarCheck,
  FaTimes,
} from "react-icons/fa";
import { FaBarsProgress } from "react-icons/fa6";
import { Dropdown } from "react-bootstrap";
// import Custom_Modal from "./modal/CustomModal";
// import Edit_profile from "./modal/EditProfile";
// import Change_password from "./modal/ChangePassword";
// import Change_language from "./modal/ChangeLanguage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postApi } from "../services/api";
// import userImg from "../../styles/assets/image/Nav_img.svg";
import EditProfile from "./modal/EditProfile";

interface HeaderProps {
  linkTexts: any[];
  children: any;
}

const Header: React.FC<HeaderProps> = ({ linkTexts = [], children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [profileData, setProfileData] = useState<any>({});
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const location: any = useLocation();

  useEffect(() => {
    const getProfileData = async () => {
      let response: any = await postApi("admin/profile/view", {});

      if (response.status === 200) {
        setProfileData(response.data.data.admin);
      }
    };

    getProfileData();
  }, []);
  // console.log("✌️profileData --->", profileData);

  const onClickLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <>
      <nav className="navbar">
        <div className={`page_links ${isSidebarOpen ? "open" : ""}`}>
          <button className="close-btn" onClick={toggleSidebar}>
            <FaTimes />
          </button>
          <ul className="d-flex gap-4">
            {linkTexts.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  className={
                    item?.path === location.pathname + location?.search
                      ? "active"
                      : ""
                  }
                >
                  {item?.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="navbar_right">
          <FaMoon />

          <Dropdown className="notification">
            <Dropdown.Toggle variant="light" id="notification">
              <FaBell />
              <span
                className="position-absolute top-0 start-100 translate-middle badge badge-circle bg-danger end-0 w-100"
                id="counter"
              >
                18
              </span>
              <span className="caret-down"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="text-start border-bottom py-2 px-3">
                <h5>Notifications</h5>
              </div>
              <div className="px-3 mt-3 inner-scroll">
                {[...Array(4)].map((_, i) => (
                  <div
                    className="d-flex position-relative mb-4 notification"
                    key={i}
                  >
                    <div className="me-3 text-primary fs-5 icon-label">
                      <FaCalendarCheck />
                    </div>
                    <div>
                      <a href="#" className="text-decoration-none">
                        <h5 className="text-gray-900 fs-6 mb-2">
                          2345Ap 656Api appointment has been booked.
                        </h5>
                      </a>
                      <h6 className="text-gray-600 fs-small fw-light mb-0">
                        2 hours
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-center border-top p-2 mark-read">
                <h6>
                  <a
                    href="#"
                    className="text-primary mb-0 fs-5 read-all-notification text-decoration-none"
                    id="readAllNotification"
                  >
                    Mark All As Read
                  </a>
                </h6>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          {/* <div className="user_icn">
            <img src={userImg} alt="Nav Image" />
          </div> */}
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-user"
              className="user_dropdown"
            >
              {profileData.userName}
              <span className="caret-down"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="">
              <div className="text-center border-bottom pb-3">
                {/* <div className="img_circle">
                  <img src={userImg} alt="Nav Image" />
                </div> */}
                <h3 className="text-gray-900">{profileData.userName}</h3>
                <h4 className="mb-0 fw-400 fs-6">
                  {profileData?.emailAddress}
                </h4>
              </div>
              <ul className="dropdown_links">
                <li>
                  <EditProfile profileData={profileData} />
                </li>
                {/* <li>
                  <Custom_Modal
                    icon={<FaLock className="me-2 text-gray-600" />}
                    modalTitle="Change Password"
                    buttonTitle="Change Password"
                    modalContent={<Change_password />}
                    modal_class="modal"
                  />
                </li> */}
                {/* <li>
                  <Custom_Modal
                    icon={<FaGlobe className="me-2 text-gray-600" />}
                    modalTitle="Change Language"
                    buttonTitle="Change Language"
                    modalContent={<Change_language />}
                    modal_class="modal"
                  />
                </li> */}
                <li>
                  <button type="button" onClick={() => onClickLogout()}>
                    <FaSignOutAlt className="me-2 text-gray-600" /> Logout
                  </button>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          <FaBarsProgress onClick={toggleSidebar} className="togglePagelinks" />
        </div>
      </nav>
      {children}
    </>
  );
};

export default Header;
