
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import TablePagination from "../common/TablePagination";
import { FaTrash, FaEye, FaEdit } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import DeleteModal from "../common/modal/DeleteModal";
import { postApi } from "../services/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Function to format the date
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleDateString("en-US", options);
};

const HospitalListing = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(""); // State to store sorting option
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [hospitalList, setHospitalList] = useState<any[]>([]); // Explicitly define type as any[] or your hospital item type
  const [itemIdToDelete, setItemIdToDelete] = useState<string | null>(null);

  // Calculate totalItems and totalPages based on hospitalList length
  const totalItems = hospitalList.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Function to handle items per page change
  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
  };

  // Function to toggle filter dropdown
  const toggleFilterDropdown = () => {
    setIsFilterDropdownOpen(!isFilterDropdownOpen);
  };

  // Effect to fetch hospital data on component mount
  useEffect(() => {
    getHospitalsData();
  }, []);

  // Function to fetch hospital data
  const getHospitalsData = async () => {
    try {
      const response: any = await postApi("admin/hospital/list", {});

      if (response.status === 200) {
        setHospitalList(response.data.data.hospital);
      }
    } catch (error) {
      console.error("Error fetching hospital data:", error);
    }
  };

  // Function to handle delete confirmation
  const handleConfirmDelete = async (itemId: string) => {
    try {
      const finalData = {
        id: itemId,
      };

      const response = await postApi("admin/hospital/delete", finalData);

      if (response.status === 200) {
        toast.success(response.data.message);
        setItemIdToDelete(null);
        getHospitalsData(); // Refresh hospital data after deletion
      }
    } catch (error) {
      console.error("Error deleting hospital:", error);
    }
  };

  // Function to filter hospital list based on searchTerm
  const filteredHospitalList = hospitalList.filter((item: any) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to handle sorting dropdown change
  const handleSortChange = (option: string) => {
    setSortBy(option);

    // Perform sorting logic based on selected option
    let sortedList = [...hospitalList];

    if (option === "alphabetical") {
      sortedList.sort((a, b) => {
        if (a && b && a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      });
    } else if (option === "date") {
      sortedList.sort((a, b) => {
        if (a && b && a.createdAt && b.createdAt) {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }
        return 0;
      });
    }

    setHospitalList(sortedList);
  };

  return (
    <div>
      {/* Search and Filter Section */}
      <div className="d-lg-flex justify-content-between align-items-center mb-sm-7 mb-4">
        <div className="body_search">
          <BsSearch />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-end flex-wrap">
          <div className="dropdown me-2">
            <button
              className="btn btn-icon  dropdown-toggle"
              type="button"
              onClick={toggleFilterDropdown}
              id="dropdown-basic"
            >
              <FaFilter />
            </button>
            <ul
              className={`dropdown-menu ${isFilterDropdownOpen ? "show" : ""}`}
            >
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSortChange("alphabetical")}
                >
                  Sort by Name (A-Z)
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSortChange("date")}
                >
                  Sort by Date
                </button>
              </li>
              {/* Add more sorting options if needed */}
            </ul>
          </div>
          <button
            onClick={() => navigate("/hospitals/create")}
            type="button"
            className="btn btn_style"
          >
            Add New Hospital
          </button>
        </div>
      </div>
      {/* Table Section */}
      <div className="table-responsive">
        <Table hover className="admin_table mt-4">
          <thead>
            <tr>
              <th></th>
              <th className="fw-bolder">Hospital Name</th>
              <th className="fw-bolder">Email</th>
              <th className="fw-bolder">Number</th>
              <th className="fw-bolder">City</th>
              <th className="fw-bolder">Created On</th>
              <th className="fw-bolder">Status</th>
              <th className="fw-bolder">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredHospitalList.map((item: any, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="image image-circle image-mini me-3">
                      <a href="#">
                        <div className="table_img">
                          <img
                            src={
                              item.image !== ""
                                ? item.image
                                : "https://lh3.googleusercontent.com/p/AF1QipNTNnPFfrSHbbvYx9K2Cz_V7kpOMgyVOgnm8SrX=s680-w680-h510"
                            }
                            alt="Navimg"
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex flex-column">
                    <a href="/">{item.name}</a>
                  </div>
                </td>
                <td>
                  <div>{item.emailAddress}</div>
                </td>
                <td>
                  <div>{item.mobileNumber}</div>
                </td>
                <td>
                  <div>{item.location}</div>
                </td>
                <td>
                  <div className="badge bg-light-info">
                    {formatDate(item.createdAt)}
                  </div>
                </td>
                <td>
                  <div className="badge bg-light-info">{item.status}</div>
                </td>
                <td>
                  <div className="action_icn">
                    <button
                      type="button"
                      className="edit border-0 bg-transparent"
                      onClick={() =>
                        navigate(`/hospitals/update/${item._id}`, {
                          state: { item },
                        })
                      }
                    >
                      <FaEdit />
                    </button>
                    <span
                      className="delete"
                      onClick={() => setItemIdToDelete(item._id)}
                    >
                      <FaTrash />
                    </span>
                    <button
                      type="button"
                      className="border-0 bg-transparent"
                      onClick={() =>
                        navigate(`/hospitals/${item._id}`, { state: { item } })
                      }
                    >
                      <span className="view">
                        <FaEye />
                      </span>
                    </button>
                  </div>
                </td>
                {itemIdToDelete === item._id && (
                  <DeleteModal
                    onDelete={() => handleConfirmDelete(item._id)}
                    onClose={() => setItemIdToDelete(null)}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* Pagination Section */}
      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        startIndex={startIndex}
        endIndex={endIndex}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </div>
  );
};

export default HospitalListing;
