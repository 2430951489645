


import React from 'react';

interface WidgetProps {
    href: string;
    icon: JSX.Element;
    title: string;
    count: string;
    subtitle: string;
    backgroundColor: string;
    iconColor: string;
}

const Widget: React.FC<WidgetProps> = ({ href, icon, title, count, subtitle, backgroundColor, iconColor }) => (
    <div className="dashbord_cards d-flex flex-column h-75">
        <a href={href} className="text-decoration-none super-admin-dashboard h-100 d-block">
            <div className={`bg-${backgroundColor} shadow-md rounded-10 p-xxl-10 px-4 py-5 d-flex align-items-stretch justify-content-between my-sm-3 my-2 h-100`}>
                <div className={`bg-${iconColor} widget-icon rounded-10 me-2 d-flex align-items-center justify-content-center`}>
                    {icon}
                </div>
                <div className="text-end text-white d-flex flex-column justify-content-top">
                    <h2 className="fs-1-xxl fw-bolder text-white mb-0">{count}</h2>
                    <span className="fs-5 fw-light text-white">{subtitle}</span>
                </div>
            </div>
        </a>
    </div>
);

export default Widget;
